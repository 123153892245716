
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'field-visit-report',
  components: {
    Datatable,
  },

  data() {
    return {
      pdfHeader: [] as any,
      visitortypes: [] as any,
      VisitorTypeData: [] as any,
      EmployeeData: [] as any,
      employeeList: [] as any,
      EmployeeName: [] as any,
      entityList: [] as any,
      EntityData: [] as any,
      EntityName: [] as any,
      instituteList: [] as any,
      InstituteData: [] as any,
      visit_date_from: '',
      visit_type: '' as any,
      visit_date_to: '',
      submission_date_from: '',
      submission_date_to: '',
      InstituteName: [] as any,
      VisitorTypeLabel: [] as any,
      selectedDistricts: [] as any,
      updatedArray: [] as any,
      tableData: [] as any,
      tableHeader: [
        {
          name: 'SL',
          key: 'sl',
          sortable: true,
          selected: true,
        },
        {
          name: 'Type of Visitor',
          key: 'type_of_visitor',
          sortable: true,
          selected: true,
        },
        {
          name: 'Type of Visit',
          key: 'type_of_visit',
          sortable: true,
          selected: true,
        },
        {
          name: 'Visiting Officer',
          key: 'visiting_officer',
          sortable: true,
          selected: true,
        },
        {
          name: 'Date of Visit',
          key: 'date_of_visit',
          sortable: true,
          selected: true,
        },
        {
          name: 'Name of Entity',
          key: 'entity_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Entity Address',
          key: 'entity_address',
          sortable: true,
          selected: true,
        },
        {
          name: 'Name of Institute',
          key: 'institute_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Address Institute',
          key: 'address',
          sortable: true,
          selected: true,
        },
        {
          name: 'Date of Report Submission',
          key: 'report_submission_date',
          sortable: true,
          selected: true,
        },
      ] as any,

      st: [] as any,
      moment: '' as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.getVisitorTypes();
    await this.getEntity();
  },
  methods: {
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      let entity_id = this.EntityData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('visitor_type', this.VisitorTypeData);
      formData.set('entity', entity_id);
      formData.set('employee', this.EmployeeData);
      formData.set('institute', this.InstituteData);
      formData.set('visit_date_from', this.visit_date_from);
      formData.set('visit_date_to', this.submission_date_to);
      formData.set('submission_date_from', this.submission_date_from);
      formData.set('submission_date_to', this.submission_date_to);
      formData.set('visit_type', this.visit_type);

      axios
        .post(`${this.VUE_APP_API_URL}/api/report/visit_excel`, formData, {
          responseType: 'blob',
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'FieldVisit.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async printPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      let entity_id = this.EntityData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
         formData.set('visitor_type', this.VisitorTypeData);
      formData.set('entity', entity_id);
      formData.set('employee', this.EmployeeData);
      formData.set('institute', this.InstituteData);
      formData.set('visit_date_from', this.visit_date_from);
      formData.set('visit_date_to', this.submission_date_to);
      formData.set('submission_date_from', this.submission_date_from);
      formData.set('submission_date_to', this.submission_date_to);
      formData.set('visit_type', this.visit_type);

      let data = `${this.VUE_APP_API_URL}/api/report/visit_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      let entity_id = this.EntityData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
         formData.set('visitor_type', this.VisitorTypeData);
      formData.set('entity', entity_id);
      formData.set('employee', this.EmployeeData);
      formData.set('institute', this.InstituteData);
      formData.set('visit_date_from', this.visit_date_from);
      formData.set('visit_date_to', this.submission_date_to);
      formData.set('submission_date_from', this.submission_date_from);
      formData.set('submission_date_to', this.submission_date_to);
      formData.set('visit_type', this.visit_type);
      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/visit_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    visitorTypeLabelChange(data) {
      this.VisitorTypeLabel = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.visitortypes.length; j++) {
          if (data[i] == this.visitortypes[j].id) {
            this.VisitorTypeLabel.push(this.visitortypes[j].name);
          }
        }
      }
      this.getEmployee();
    },
    EmployeeLabelChange(data) {
      this.EmployeeName = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.employeeList.length; j++) {
          if (data[i] == this.employeeList[j].id) {
            this.EmployeeName.push(this.employeeList[j].name);
          }
        }
      }
    },
    EntityLabelChange(data) {
      this.EntityName = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.entityList.length; j++) {
          if (data[i] == this.entityList[j].id) {
            this.EntityName.push(this.entityList[j].entity_short_name);
          }
        }
      }
      this.getInstitute();
    },
    InstituteLabelChange(data) {
      this.InstituteName = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.instituteList.length; j++) {
          if (data[i] == this.instituteList[j].id) {
            this.InstituteName.push(this.instituteList[j].short_name);
          }
        }
      }
    },
    async getEmployee() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        'fieldvisit-report/employee?visitor_type=' +
          this.VisitorTypeData +
          '&entity_id=' +
          entity_id
      )
        .then((response) => {
          this.employeeList = response.data.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getVisitorTypes() {
      let entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
      await ApiService.get('fieldvisit/type?entity_type_id=' + entity_type_id)
        .then((response) => {
          this.visitortypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntity() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('fieldvisit-report/entity?entity_id=' + entity_id)
        .then((response) => {
          this.entityList = response.data.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getInstitute() {
      await ApiService.get(
        'institute/filterInstitute?entity=' + this.EntityData
      )
        .then((response) => {
          this.instituteList = response.data.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getData() {
      let entity_id = this.EntityData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      ApiService.get(
        'fieldvisit-report/details?visitor_type=' +
          this.VisitorTypeData +
          '&entity=' +
          entity_id +
          '&institute=' +
          this.InstituteData +
          '&employee=' +
          this.EmployeeData +
          '&visit_date_from=' +
          this.visit_date_from +
          '&visit_date_to=' +
          this.visit_date_to +
          '&submission_date_from=' +
          this.submission_date_from +
          '&submission_date_to=' +
          this.submission_date_to +
          '&visit_type=' +
          this.visit_type
      )
        .then((response) => {
          this.load = false;
          this.headerSelect = true;
          this.selectedTableHeader();
          this.tableData = response.data.data;
          this.componentTableKey += 1;
          this.showTableData = true;
        })
        .catch((response) => {
          console.log(response);
          this.load = false;
        });
    },

    resetData() {
      this.tableData = [] as any;
      this.VisitorTypeLabel = [] as any;
      this.EmployeeName = [] as any;
      this.EntityName = [] as any;
      this.InstituteName = [] as any;
      this.VisitorTypeData = [] as any;
      this.EntityData = [] as any;
      this.InstituteData = [] as any;
      this.EmployeeData = [] as any;
      this.submission_date_to = '';
      this.submission_date_from = '';
      this.visit_date_to = '';
      this.visit_date_from = '';

      this.st = [] as any;
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },

    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
      // this.tableHeader.map((item) => {
      //   if (item.selected) {
      //     this.st.push(
      //       JSON.parse(JSON.stringify({ name: item.name, key: item.key }))
      //     );
      //   } else {
      //   }
      // });
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() {},
});
